<template>
    <!-- 这里是“造价预算人员”详情页面 -->
    <div class="mb-50">
        <div class="list-z">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
                <el-breadcrumb-item :to="{ path: '/engineering/index' }"
                >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/creative-personnel/index' }"
                >造价人员</el-breadcrumb-item
                >
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="main">
            <el-row type="flex" align="middle" style="margin-bottom: 70px">
                <el-col :span="24">
                    <div class="line" style="padding: 20px 0px">
                        <div class="name-t">{{ contactName }}</div>
                        <div class="a-flex flex-between">
                            <div class="a-flex">
                                <div class="back1 mr-10">{{ model.workAge || "-" }}</div>
                                <div class="back1">{{ model.proveSendDate || "-" }}</div>
                            </div>
                            <div>
                                <el-button size="mini" icon="el-icon-star-on" @click="getCommonCollectionAddOrDel" v-show="isCollect" style="color: #ffc94b;">收藏</el-button>
                                <el-button size="mini" icon="el-icon-star-off" @click="getCommonCollectionAddOrDel" v-show="!isCollect">收藏</el-button>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <!-- 基本详情 -->
            <el-row>
                <el-col :span="24">
                    <div class="line line-top a-flex flex-center">
                        <div class="border-left"></div>
                        <div class="title">基本详情</div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="basic mb-80">
                <el-col :span="12" class="text-left">
                    <div class="mb-40">
                        人员姓名：<span>{{ contactName || "-" }}</span>
                    </div>
                    <div>
                        身份证号：<span>{{ idCard || "-" }}</span>
                    </div>
                </el-col>
                <el-col :span="12" class="text-left">
                    <div class="a-flex mb-40">
                        <div>
                            联系电话：<span>{{ isContactNumber ? '***********' : model.contactNumber }}</span>
                        </div>
                        <div class="view" @click="onContactNumber"><i class="el-icon-view" style="padding-right: 4px;"></i>{{isContactNumber ? '查看' : '隐藏'}}</div>
                    </div>
                    <div>
                        联系地址：<span>{{ model.address || "-" }}</span>
                    </div>
                </el-col>
            </el-row>
            <!-- 人员资质 -->
            <el-row>
                <el-col :span="24">
                    <div class="line line-top a-flex flex-center">
                        <div class="border-left"></div>
                        <div class="title">人员资质</div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="basic mb-80">
                <el-col :span="24" class="mb-40">
                    <el-col :span="12" class="text-left">
                        <div class="fs-16">
                            意向岗位：<span>{{ model.proveName || "-" }}</span>
                        </div>
                    </el-col>
                    <el-col :span="12" class="text-left">
                        <div class="fs-16">
                            合作性质：<span>{{
                model.natureCooperation === "single"
                  ? "单次"
                  : "forever"
                  ? "长期合作"
                  : "contract"
                  ? "承包"
                  : "-"
              }}</span>
                        </div>
                    </el-col>
                </el-col>
                <el-col :span="24" class="mb-40 text-left">
                    <div class="fs-16">
                        工作年限：<span>{{ model.workAge || "-" }}</span>
                    </div>
                </el-col>
                <el-col :span="24" class="mb-40 text-left">
                    <div class="fs-16">
                        擅长软件：<span>{{ model.software || "-" }}</span>
                    </div>
                </el-col>
                <el-col :span="24" class="mb-40 text-left">
                    <div class="fs-16">
                        擅长专业：<span>{{ model.specialized || "-" }}</span>
                    </div>
                </el-col>
                <el-col :span="24" class="text-left">
                    <div class="fs-16">
                        项目经验：<span>{{ model.describes || "-" }}</span>
                    </div>
                </el-col>
            </el-row>
            <!-- 岗位信息 -->
            <el-row>
                <el-col :span="24">
                    <div class="line line-top a-flex flex-center">
                        <div class="border-left"></div>
                        <div class="title">岗位信息</div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="basic mb-80">
                <el-col :span="12" class="text-left">
                    <div class="mb-40">
                        岗位名称：<span>{{ model.proveName || "-" }}</span>
                    </div>
                    <div>
                        岗位编号：<span>{{ model.proveNumber || "-" }}</span>
                    </div>
                </el-col>
                <el-col :span="12" class="text-left">
                    <div class="a-flex mb-40">
                        <div>
                            注册专业：<span>{{ model.proveSpecialized || "-" }}</span>
                        </div>
                    </div>
                    <div>
                        发证日期：<span>{{ model.proveSendDate || "-" }}</span>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
    import { getBusinessDetail, urlCommonCollectionCheck, urlCommonCollectionAddOrDel } from "@/api/engineering";
    export default {
        data() {
            return {
                expandedRows: [],
                model: {
                    contact: "",
                    idCard: "",
                    contactNumber: "",
                    address: "",
                    category: "",
                    natureCooperation: "",
                    workAge: "",
                    software: "",
                    specialized: "",
                    describes: "",
                    typeName: "",
                    type: "",
                    proveSpecialized: "",
                    startdate: "",
                },
                isContactNumber: true,
                isCollect: false
            };
        },
        computed: {
            contactName() {
                if (this.model.contact) {
                    let contactNameLength = this.model.contact.length;
                    let tips = "*";
                    for (let i = 1; i < contactNameLength - 1; i++) {
                        tips = tips + "*";
                    }
                    return this.model.contact.substring(0, 1) + tips;
                }
                return "-";
            },
            idCard() {
                if (this.model.idCard) {
                    let idCard = this.model.idCard.length;
                    let tips = "*";
                    for (let i = 1; i < idCard - 5; i++) {
                        tips = tips + "*";
                    }
                    return (
                        this.model.idCard.substring(0, 3) +
                        tips +
                        this.model.idCard.substring(16)
                    );
                }
                return "-";
            },
        },

        mounted() {
            this.getBusinessDetail(this.$route.query.id);
            this.getCommonCollectionCheck(this.$route.query.id);
        },
        methods: {
            onContactNumber() {
                this.isContactNumber = !this.isContactNumber
            },
            async getCommonCollectionAddOrDel() {
                const param = {
                    bid: this.model.id,
                    type: "costBudgetPerson"
                }
                const res = await urlCommonCollectionAddOrDel(param)
                this.isCollect = !this.isCollect
            },
            async getCommonCollectionCheck(bid) {
                const param = {
                    bid: bid,
                    type: "costBudgetPerson"
                }
                const res = await urlCommonCollectionCheck(param)
                this.isCollect = res.data
                console.log('isCollect', this.isCollect)
            },
            async getBusinessDetail(id) {
                const param = {
                    id: id,
                };
                // 获取API
                const res = await getBusinessDetail(param);
                this.model = res.data;
                // this1.contact = contactName;
            },
        },
    };
</script>
<style scoped>
    .a-flex {
        display: flex;
    }
    /* 上下左右 */
    .flex-between {
        justify-content: space-between;
        align-items: center;
    }
    /* 垂直 */
    .flex-center {
        align-items: center;
    }
    .main {
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        color: #4e4e4e;
    }
    .list-z {
        text-align: left;
        font-weight: 500;
        color: #4e4e4e;
        line-height: 40px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .mb-50 {
        margin-bottom: 50px;
    }
    .mb-40 {
        margin-bottom: 40px;
    }
    .mb-80 {
        margin-bottom: 80px;
    }
    .fs-18 {
        font-size: 18px;
    }
    .fs-16 {
        font-size: 16px;
    }

    .mr-10 {
        margin-right: 12px;
    }
    .line {
        border-bottom: 1px solid #dcdcdc;
    }
    .name-t {
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #212121;
        text-align: left;
        margin-bottom: 20px;
    }
    .back1 {
        height: 28px;
        background: #f6f7fb;
        border-radius: 4px 4px 4px 4px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        padding: 0px 10px;
        line-height: 28px;
        color: #4e4e4e;
    }
    .border-left {
        width: 10px;
        height: 16px;
        background: #007ef2;
    }
    .title {
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #4e4e4e;
        margin-left: 10px;
    }
    .line-top {
        padding-bottom: 20px;
        margin-bottom: 40px;
    }
    .text-left {
        text-align: left;
    }
    .basic {
        font-size: 16px;
        font-weight: 400;
        color: #4e4e4e;
    }

    .view {
        font-size: 16px;
        font-weight: 400;
        color: #007ef2;
        margin-left: 20px;
        cursor: pointer;
    }
</style>
